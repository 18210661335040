<template>
  <div>
    <b-row>
      <b-col cols="11">
        <b-form-group
          label="Nome/Nome empresarial"
        >
          <b-input
            v-model="value.nome"
            placeholder="Nome"
          />
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-btn
          v-b-tooltip.hover
          title="Remover"
          variant="none"
          style="margin-top: 30px;"
          class="btn-outline-danger btn-none rounded-pill icone-acao"
          @click="removerSocio()"
        >
          <feather-icon icon="TrashIcon" />
        </b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-form-group
          label="Qualificação"
        >
          <b-input
            v-model="value.qualificacao"
            placeholder="Nmr qualificação"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group
          label="Descrição"
        >
          <b-input
            v-model="value.descricao"
            placeholder="Descrição qualificação"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row :class="value.filiacoes && value.filiacoes.length > 0 ? 'accordion': 'accordion disabled'">
      <b-col
        cols="12"
      >
        <div
          @click="mostrarEmpresas()"
        >
          <span style="font-weight: bold">Outras Empresas: </span>
          <b-badge
            v-if="value.filiacoes && value.filiacoes.length > 0"
            variant="primary"
          >
            + {{ value.filiacoes.length }}
          </b-badge>
          <div style="float:right">
            <feather-icon
              v-if="show"
              style="margin-right:0.25rem"
              icon="ChevronUpIcon"
            />
            <feather-icon
              v-else
              style="margin-right:0.25rem"
              icon="ChevronDownIcon"
            />
          </div>
        </div>
        <b-collapse
          v-model="show"
        >
          <b-card-body>
            <divisoria-descricao
              titulo="Demais empresas do sócio"
              descricao="Lista de empresas que esse sócio faz parte"
              espaco-extra
            >
              <template slot="valor-extra">
                <div style="float:right;">
                  <b-btn
                    variant="none"
                    class="btn btn-outline-primary mr-1"
                    size="sm"
                    @click="novaEmpresa()"
                  >
                    <feather-icon
                      style="margin-right:0.25rem"
                      icon="PlusIcon"
                    />Novo
                  </b-btn>
                </div>
              </template>
            </divisoria-descricao>
            <div
              v-for="(filiacao, index) in value.filiacoes"
              :key="index"
              class="mb-2"
            >
              <b-row>
                <b-col cols="7">
                  <b-form-group
                    label="Razão Social"
                  >
                    <b-input
                      v-model="filiacao.razaoSocial"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group
                    label="Qualificação"
                  >
                    <b-input
                      v-model="filiacao.qualificacao"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group
                    label="Descrição"
                  >
                    <b-input
                      v-model="filiacao.descricao"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="1">
                  <b-btn
                    v-b-tooltip.hover
                    title="Remover"
                    variant="none"
                    style="margin-top: 30px;"
                    class="btn-outline-danger btn-none rounded-pill icone-acao"
                    @click="removerEmpresa(index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-btn>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="2">
                  <b-form-group
                    label="Regime"
                    label-for="regimeSelect"
                  >
                    <template slot="label">
                      <span
                        class="mr-50"
                        style="font-size: 1rem"
                      >Regime</span>
                      <span
                        v-b-tooltip.hover
                        title="Preencha manualmente o regime conforme necessário para a empresa."
                        style="padding-right: 5px;"
                        class="text-primary"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          size="15"
                        />
                      </span>
                    </template>
                    <b-select
                      v-model="filiacao.empresaRegime"
                      :options="[
                        {text: 'Não informado', value: 'none'},
                        {text: 'Simples nacional', value: 'simplesnacional'},
                        {text: 'MEI', value: 'mei'} ,
                        {text:'Normal', value: 'normal'}]"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    label="Natureza jurídica"
                  >
                    <b-input
                      v-model="filiacao.naturezaJuridica"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    label="Porte"
                  >
                    <b-input
                      v-model="filiacao.porte"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr
                    class="mx-0 mt-0 mb-1"
                    style="border-bottom: 1px solid #a3a4a66b !important;"
                  >
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-collapse>
      </b-col>
    </b-row>
  </div>
</template>

  <style lang="scss">
  .accordion {
    background-color: rgb(242, 242, 242);
    border-radius: 5px;
    padding: 5px;
  }

  .accordion.disabled {
    background-color: rgb(248, 248, 248);
    color: rgb(150, 150, 150);
  }
  </style>

<script>

export default {
  name: 'EmpresaSocios',
  components: {
    DivisoriaDescricao: () => import('@pilar/components/divisoria-descricao/DivisoriaDescricao.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    mostrarEmpresas() {
      if (this.show) {
        this.show = false
      } else {
        this.show = true
      }
    },
    removerSocio() {
      this.$emit('remover-socio')
    },
    novaEmpresa() {
      if (!this.value.filiacoes) {
        this.$set(this.value, 'filiacoes', [{}])
      } else {
        this.value.filiacoes.push({})
      }
    },
    removerEmpresa(index) {
      this.$confirmar('Remover?', 'Tem certeza que deseja excluir o registro?', 'warning')
        .then(result => {
          if (result.value) {
            this.value.filiacoes.splice(index, 1)
          }
        })
    },
  },
}
</script>
